.Table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.TitleBox {
  padding: 6px 15px;
  background-color: #111;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  border: 2px solid #111;
}

.TitleBox button {
  font-size: 1rem;
  box-shadow: none;
}

.Table th,
.Table td {
  padding: 5px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
}

.Table td label {
  margin-bottom: -2px;
}

.Table th {
  padding-top: 8px;
}

.Table th:last-of-type {
  width: 50px;
}

.Table th:nth-of-type(5),
.Table th:nth-of-type(6),
.Table td:nth-of-type(5),
.Table td:nth-of-type(6) {
  text-align: right;
}

.Table thead {
  border-bottom: 2px solid #ccc;
  background-color: #eee;
  background-image: linear-gradient(0deg, #ddd, #f4f4f4 10%, #f4f4f4 90%, #ddd);
  color: #111;
  text-transform: uppercase;
}

.Table tbody tr:hover {
  background-color: #f4f4f4;
}

.Table img {
  width: 50px;
  display: block;
}

@media (max-width: 700px) {
  .CollapseLarge {
    display: none;
  }
}

@media (max-width: 600px) {
  .CollapseMedium {
    display: none;
  }
}

@media (max-width: 400px) {
  .CollapseSmall {
    display: none;
  }
}
