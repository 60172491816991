.Button {
  float: right;
  padding: 5px 10px;
  margin-left: 10px;
  background-color: transparent;
  cursor: pointer;
  color: white;
  position: relative;
  border: none;
}

.Edit,
.View {
  background-color: rgba(50, 80, 255, 0.8);
  box-shadow: 0 1px 3px #ccc;
}

.Edit:hover,
.View:hover {
  background-color: rgba(50, 80, 255, 1);
}

.Exit {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  margin-top: 5px;
  padding-bottom: 0;
  color: #000;
}

.Exit svg {
  margin-left: 3px;
  margin-top: 1px;
  margin-bottom: 0;
}

.Exit:hover {
  background-color: rgba(255, 255, 255, 1);
}

.Edit:hover::before,
.Delete:hover::before,
.Add:hover::before,
.View:hover::before {
  content: "";
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 10;
}

.Edit:hover::after,
.Delete:hover::after,
.Add:hover::after,
.View:hover::after {
  background-color: #333;
  font-size: 12px;
  color: white;
  position: absolute;
  padding: 4px 8px;
  border-radius: 4px;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -100%);
  border: 1px solid #ccc;
}

.Edit:hover::after {
  content: "Edit";
}

.Delete:hover::after {
  content: "Delete";
}

.Add:hover::after {
  content: "Add";
}

.View:hover::after {
  content: "View";
}

.Delete {
  background-color: rgba(200, 30, 60, 0.8);
  box-shadow: 0 1px 3px #888;
}

.Delete:hover {
  background-color: rgba(200, 30, 60, 1);
}

.Submit,
.Cancel {
  box-shadow: 0 1px 3px #ccc;
  font-weight: 500;
}

.Cancel {
  background-color: rgba(180, 10, 40, 0.8);
}

.Cancel:hover {
  background-color: rgba(200, 30, 60, 1);
}

.Submit {
  background-color: rgba(30, 60, 235, 0.8);
}

.Submit:hover {
  background-color: rgba(50, 80, 255, 1);
}

.Add {
  background-color: rgba(30, 200, 60, 0.8);
  box-shadow: 0 1px 3px #888;
}

.Add:hover {
  background-color: rgba(30, 200, 60, 1);
}

.Default:hover {
  text-decoration: underline;
}

.left {
  float: left;
  margin-left: 0;
  margin-right: 10px;
  padding: 8px 15px;
}

.right {
  float: right;
  padding: 8px 20px;
}

.none {
}

.Button:disabled {
  background-color: #888;
  cursor: not-allowed;
}
