.Page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.MainBody {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 1px; /* prevents bug in ie */
}

.Content {
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px 20px 30px 20px;
  color: #111;
  min-height: 1px; /* prevents bug in ie */

  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}


@media (max-width: 400px) {
  .Content {
    padding: 20px 10px;
  }
}