.SubcategoriesBox {
  margin-bottom: 20px;
  margin-left: -1%;
  margin-right: -1%;
  min-height: 1px; /* prevents bug in ie */
}

.Subcategories {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 400px) {
  .SubcategoriesBox {
    margin-left: 0;
    margin-right: 0;
  }
}
