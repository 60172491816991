.BreadCrumbsRow {
  width: 100%;
  margin-right: auto;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
  border-radius: 4px;
}

.BreadCrumbs {
  color: #555;
  font-size: .9rem;
}

.Arrow {
  margin: 0 8px;
}

.Last {
  font-weight: 500;
  margin: 0;
}

.Link {
  color: #555;
}

.Link:hover {
  color: #000;
  text-decoration: underline;
}


@media (max-width: 400px) {
  .BreadCrumbs {
    display: none;
  }
}