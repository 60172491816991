.TitleBox {
  margin-bottom: 25px;
  text-align: center;
}

.Title {
  font-size: 1.4rem;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.Left {
  text-align: left;
}

.Warning {
  padding-top: 10px;
  color: #d00;
}

.ErrorMessage {
  color: #d00;
  text-align: left;
}

.Label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.Input {
  margin-bottom: 20px;
  width: 100%;
  background-color: #f5f5f5;
}

.Button {
  margin-top: 10px;
  padding: 10px 20px;
  width: 100%;
  background-color: #fc6;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.Button:hover {
  background-color: #fd7;
}

.BottomText span {
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
}

.BottomText span:hover {
  text-decoration: underline;
}

.BottomText {
  text-align: center;
  margin-top: 10px;
  font-size: .9rem;
}

.Invalid {
  border: 1px solid #f00;
}

@media (max-width: 500px) {
  .Left {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .Form {
    width: 100%;
  }
}