* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* For testing font size effect on layout */
  /* font-size: 30px;  */
}

body {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  background-color: #fcfcfc;
  width: 100%;
}

#root {
  /* Prevents flex bug in IE */
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  padding: 0.3rem 0.6rem;
  border: 1px solid #aaa;
  border-radius: 4px;
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

button:focus:active {
  outline: none;
}

button:active {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.8);
}
