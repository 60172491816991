.Title {
  font-weight: 500;
  font-size: 1.3rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}

.Line {
  border-bottom: 1px solid #ddd;
  margin-bottom: 35px;
}

.ProductsBox {
  margin-bottom: 20px;
  margin-left: -1%;
  margin-right: -1%;
  min-height: 1px; /* prevents bug in ie */
  display: flex;
  flex-wrap: wrap;
}

.None {
  margin: 0 10px;
}

@media (max-width: 400px) {
  .None {
    width: 100%;
    text-align: center;
  }

  .ProductsBox {
    margin-left: 0;
    margin-right: 0;
  }
}
