.Title {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  padding-bottom: .5rem;
}

@media (max-width: 375px) {
  .Title {
    text-align: center;
  }
}