.CloseButton {
  position: absolute;
  left: 80vw;
  line-height: 1.2rem;
  margin-left: -2.25rem;
  padding: 20px 0;
  font-size: 2.5rem;
  color: #eee;
  cursor: pointer;
  z-index: 999;
}

.CloseButton:hover {
  color: #fff;
}

.Nav {
  margin-bottom: 14px;
}

.NavItem,
.NavLink {
  display: block;
  padding: 6px 20px;
  cursor: pointer;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.NavItem::after {
  content: ">";
  position: absolute;
  right: 20px;
}

.NavItem:hover,
.NavLink:hover {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  background-color: #eee;
}

.BackButton {
  font-weight: 500;
  color: #666;
  margin-bottom: 10px;
  padding: 6px 20px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.1rem;
  border: none;
}

.BackButton:hover {
  color: #333;
}

.Line {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #aaa;
}

.NavTitle {
  font-weight: 500;
  color: #888;
  margin-bottom: 5px;
  padding: 0 20px;
  text-transform: uppercase;
}

.Brand {
  padding: 20px;
  background-color: #123;
  color: #eee;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 1.25rem;
}

.SlideContent {
  transition: transform 0.3s ease;
  position: absolute;
  width: 100%;
}

.SlideContent li {
  list-style: none;
}

.SlideContent.Center {
  transform: translateX(0);
}

.SlideContent.Left {
  transform: translateX(-100%);
}

.SlideContent.Right {
  transform: translateX(100%);
}
