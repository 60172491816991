.MainNav {
  color: #ddd;
  border-radius: 4px;
  padding: 5px 20px 5px 0;
}

.MainNav button {
  background-color: #123;
  /* padding: 2px 6px; */
  padding: 0;
  border: none;
  overflow: hidden;
}

.MainNav svg {
  cursor: pointer;
  display: block;
  margin: -2px 0;
  /* width: 2rem; */
  /* height: 2rem; */
  width: 2.5rem;
  height: 2.5rem;
}

.MainNav svg:hover {
  color: #fff;
}
