/* NAV ITEMS */

.NavItem,
.NavItemWithSubs {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
  border-bottom: 2px solid transparent;
  position: relative;

  transition: all 0s;
}

.NavItem {
  cursor: pointer;
}

.NavItemWithSubs a {
  cursor: pointer;
}

.NavItem button,
.NavItemWithSubs button {
  cursor: pointer;
  padding: 2px 0;
  background-color: transparent;
  border: none;
  color: inherit;
}

.NavItemWithSubs button {
  cursor: default;
}

.NavItemWithSubs::after {
  content: "";
  display: inline-block;
  margin-left: 0.42rem;
  padding-bottom: 2px;
  border-left: 0.28rem solid transparent;
  border-right: 0.28rem solid transparent;
  border-top: 0.35rem solid #ccc;
  border-radius: 2px;
  cursor: default;
}

.NavItem:hover,
.NavItemWithSubs:hover {
  color: #fff;
}

.NavItem:hover .DownArrow {
  border-top-color: #eee;
}

.NavItemWithSubs:hover .SubBox,
.NavItemWithSubs:hover .SubBoxRight,
.NavItemWithSubs:hover .UpArrow {
  visibility: visible;
  transition-delay: 0.2s;
}

/* SUBCATEGORY BOXES */

.SubBox,
.SubBoxRight,
.SecondLevelBox,
.SecondLevelBoxRight {
  position: absolute;
  background-color: #fff;
  padding: 5px 0;
  font-size: 0.9rem;
  z-index: 5;
  border-radius: 0 0 4px 4px;
  width: 10.71rem;
  cursor: default;
  color: #000;

  visibility: hidden;
}

.SubBox,
.SubBoxRight {
  top: 100%;
  box-shadow: 0 2px 6px #666;
  /* margin-top: 2px; */
}

.SecondLevelBox,
.SecondLevelBoxRight {
  top: 0;
  min-height: 100%;
  box-shadow: 2px 2px 6px #777;
  z-index: 6;
}

.SubBox {
  left: -0.71rem;
  text-align: left;
}

.SecondLevelBox {
  left: 100%;
  text-align: left;
}

.SubBoxRight {
  right: -0.71rem;
  text-align: right;
}

.SecondLevelBoxRight {
  right: 100%;
  text-align: right;
}

/* SUBCATEGORY ITEMS */

.SubItem,
.SubItemWithSubs {
  display: block;
  cursor: pointer;
}

.SubItem span {
  cursor: pointer;
}

.SubItem:hover,
.SubItemWithSubs:hover {
  background-color: #cde;
}

.SubItemWithSubs:hover .SecondLevelBox,
.SubItemWithSubs:hover .SecondLevelBoxRight {
  visibility: visible;
  transition-delay: 0.2s;
}

.SubItemWithSubs:hover .RightArrow {
  border-left-color: #333;
}

.SubItemLink {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 6px 10px;
}

/* ARROW ICONS */

.UpArrow {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  right: -0.14rem;
  bottom: -0.15rem;
  border-left: 0.42rem solid transparent;
  border-right: 0.42rem solid transparent;
  border-bottom: 0.5rem solid #fff;
  z-index: 4;
}

.RightArrow {
  float: right;
  margin-top: -1.2rem;
  margin-right: 0.5rem;
  border-top: 0.28rem solid transparent;
  border-bottom: 0.28rem solid transparent;
  border-left: 0.35rem solid #999;
  border-radius: 2px;
}

@media (max-width: 400px) {
  .NavItem,
  .NavItemWithSubs {
    margin-right: 0;
  }
}
