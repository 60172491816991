.Row {
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.Underline {
  border-bottom: 1px solid #ddd;
}

.Row::after {
  content: "";
  clear: both;
  display: table;
}

.LeftColumn {
  float: left;
}

.RightColumn {
  float: right;
}

.ItemTitle {
  font-weight: 500;
  margin-bottom: 4px;
}

.Options {
  font-size: .9rem;
  margin-bottom: 5px;
}